export const projects = [
  {
    title: "The Botanist's Library",
    description: "The Botanist's Library is a collection of flowers, a place for gathering floral information and to keep track of community care. Built with Django, Python, HTML, CSS, Amazon S3.",
    timeline: 'Project completed in one week',
    objective: "The Botanist's Library was a fullstack reference app, built with one-to-many and many-to-many relationships.  Code and design completed as an independent project.",
    image: '/p-THE-BOTANISTS-LIBRARY-landing.png',
    image2: '/p-THE-BOTANISTS-LIBRARY-flowers.png',
    repositoryLink: 'https://github.com/kangcarol/the-botanists-library',
    deploymentLink: 'https://the-botanists-library.herokuapp.com/',
  },
  {
    title: 'Tapestry',
    description: 'App designed for contemplation, genuine interactions, to share and learn about others. Built with Mongoose, MongoDB, Express, NodeJS, CSS.',
    timeline: 'Project completed in one week',
    objective: 'Tapestry is a MEN stack app, the technical requirements included at least one entity besides the profile, Tapestry was built with three. Code and design completed as an independent project.',
    image: '/p-TAPESTRY-landing.png',
    image2: '/p-TAPESTRY-share.png',
    repositoryLink: 'https://github.com/kangcarol/tapestry',
    deploymentLink: 'https://carolkang-tapestry-app.fly.dev/',
  },
  {
    title: 'The Phoenix',
    description: 'Choose Your Own Adventure game based on the mythological Phoenix. Built with Javascript, HTML, CSS.   **Designed for desktop, mouse or trackpad required to play**',
    timeline: 'Project completed in one week',
    objective: 'The Phoenix was my first independent project. Technical requirement was to use win/lose logic.  It utilized conditional statements, DOM manipulation, CSS flex and grid features. Code and design completed as an independent project.',
    image: '/p-THE-PHOENIX-landing.png',
    image2: '/p-THE-PHOENIX-volcano.png',
    repositoryLink: 'https://github.com/kangcarol/choose-your-own-adventure-the-phoenix',
    deploymentLink: 'https://carol-kang-cyoa-the-phoenix.netlify.app/',
  },
  {
    title: 'Carol Kang Ceramics',
    description: 'A portfolio site for ceramics. Built with React, Javascript, CSS.',
    timeline: 'Project completed in less than one week',
    objective: 'Independent project created post-Software Engineering Immersive course. This project was to create a template for portfolio sites.',
    image: '/p-CAROLKANG-CERAMICS-landing.png',
    image2: '/p-CAROLKANG-CERAMICS-detail.png',
    repositoryLink: 'https://github.com/kangcarol/carolkang-ceramics',
    deploymentLink: 'https://carolkang-ceramics.netlify.app',
  },
  {
    title: 'AVI',
    description: 'AVI is an app for birders that gives them a place to connect. App built with three other developers. Built with React, Express, Mongoose, MongoDB, NodeJS, CSS.',
    timeline: 'Project completed in one week',
    objective: 'AVI is a MERN stack app created with three other software engineers. We were to architect, design, and collaboratively build a full-stack web app. All group members were each responsible for a CRUD component, as well as CSS execution.  My role was primarily as a front end engineer, creator of the design concept and logo, CRUD for the birds.',
    image: '/p-AVI-landing.png',
    image2: '/p-AVI-birds.png',
    repositoryLink: 'https://github.com/kangcarol/Avi-front-end',
    deploymentLink: 'https://avi-birding.netlify.app/',
  },
  {
    title: 'Carol Kang UX Portfolio',
    description: 'User Experience Design Portfolio, this is not an example of coding skills, built with CMS in the interim. **Work in Progress**',
    timeline: 'On going project',
    objective: 'Portfolio site to showcase my UX Design work. Research, design, and development, working cross functionally with teams to create internal business solutions.',
    image: '/p-CAROLKANG-UX-landing.png',
    image2: '/p-CAROLKANG-UX-detail.png',
    // repositoryLink: 'NA',
    deploymentLink: 'https://www.carolkang-ux.com/',
  },

  // {
  //   title: 'Tic Tac Toe',
  //   description: 'A classic game in a graphic color scheme with animation. Built with Javascript, HTML, CSS.',
  //   timeline: 'Project completed in one week',
  //   objective: 'Tic Tac Toe was my first coding assignment, utilizing conditional statements.',
  //   image: '/p-TIC-TAC-TOE-main.png',
  //   image2: '/p-TIC-TAC-TOE-play.png',
  //   repositoryLink: 'https://github.com/kangcarol/ttt-weekend',
  //   deploymentLink: 'https://carol-kang-tic-tac-toe.netlify.app/',
  // }
]